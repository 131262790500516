<template>
  <section class="modal--container auth--container" v-if="step === 1">
    <div class="title--text hide--in-mobile">
      <span v-if="from === 'profile'">{{ $t('loginRegister.verifyPhoneProfile') }}</span>
      <span v-else>{{ $t('loginRegister.verifyPhone') }}</span>
    </div>
    <SectionLoader :show="isLoading" />
    <my-alert :variant="alertType" :show="showAlert">
      <div v-html="messageAlert"></div>
    </my-alert>
    <form @submit.prevent="next" id="loginForm">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('phone') }">
        <client-only>
          <vue-tel-input
            id="phone"
            @input="phoneInput"
            :validCharactersOnly="true"
            defaultCountry="id"
            :onlyCountries="['id']"
            :inputOptions="inputOptions"
          />
        </client-only>
        <span class="val-error" v-if="validation.hasError('phone')">{{
          validation.firstError('phone')
        }}</span>
      </div>
      <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">
        {{ $t('general.next') }}
      </button>
    </form>
  </section>
  <div v-else-if="step === 2">
    <SectionLoader :show="isLoading" />
    <otp :phone="phone" from="verify-phone" @verifyOtp="verifyPhone"></otp>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
const SectionLoader = () => import('@/components/content-loading/section-loading');
const Otp = () => import('@/components/auth/otp');
const MyAlert = () => import('@/components/utils/my-alert.vue');

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  data() {
    return {
      phone: null,
      isLoading: false,
      remember: false,
      unactivated: false,
      messageAlert: null,
      showAlert: false,
      alertType: '',
      showPass: false,
      step: 1,
    };
  },
  computed: {
    inputOptions() {
      return {
        placeholder: this.$t('profile.placeholder.newPhone'),
      };
    },
  },
  props: ['formType', 'from'],
  components: {
    SectionLoader,
    Otp,
    VueTelInput,
    MyAlert,
  },
  validators: {
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
  },
  methods: {
    updateLoading(val) {
      this.isLoading = val;
    },
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.number) {
        this.phone = phoneObject.number;
      } else {
        this.phone = null;
      }
    },
    async next() {
      try {
        let self = this;
        this.hasError = false;
        this.dataError = null;
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          const response = await this.$store
            .dispatch('global/sendOtp', {
              phone: this.phone,
            })
            .catch((e) => {
              self.hasError = true;
              self.dataError = e.response.data.error;
            });
          if (!this.hasError && response.data.errors) {
            this.hasError = true;
            this.dataError = response.data.errors;
          }
          if (!this.hasError) {
            this.step = 2;
            this.isLoading = false;
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
    async verifyPhone(otp) {
      try {
        let self = this;
        this.hasError = false;
        this.dataError = null;
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          this.isLoading = true;
          const response = await this.$store
            .dispatch('global/changePhone', {
              phone: this.phone,
              otp: otp,
            })
            .catch((e) => {
              self.hasError = true;
              self.dataError = e.response.data.error;
            });
          if (!this.hasError && response.error) {
            this.hasError = true;
            this.dataError = response.error;
          }
          if (!this.hasError) {
            await this.$store.dispatch('global/fetch');
            this.isLoading = false;
            await this.$swal(
              this.$t('general.success'),
              this.$t('profile.swal.phone.success'),
              'success',
            );
            this.$emit('close');
          } else {
            const message =
              this.dataError.type === 'unactivated'
                ? this.dataError.message
                : this.dataError.message;

            const type =
              this.dataError.type === 'unactivated' || this.dataError.type === 'validation'
                ? 'danger'
                : 'success';

            this.unactivated = this.dataError.type === 'unactivated';
            this.showAlert = true;
            this.messageAlert = message;
            this.alertType = type;
            self.isLoading = false;
          }
        }

        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    // this.openModal();
  },
};
</script>
